"use client";

import classnames from "classnames/bind";
import {
  CardStackActions,
  CardStackState,
} from "../../helpers/useCardStackState";
import { SliderButton } from "../slider-button/slider-button";
import styles from "./card-stack.module.css";
import { NavigationDots } from "../navigation-dots/navigation-dots";
import { CSSProperties } from "react";
import { CosmosButtonProps } from "@cosmos/web/react";
import { rotateArray } from "../rotateArray";

const cx = classnames.bind(styles);

const TABLET_BREAKPOINT = 650;
const CARDS_IN_DOM = 5;

type KeyedCard<T> = T & {
  key: number;
};

export const CardStack = <T extends Record<string, unknown>>({
  cardStackState,
  cards,
  isWide,
  isWithHero,
  cardStackActions,
  hasNavigationDots,
  renderCard,
}: {
  cardStackState: CardStackState;
  cardStackActions: CardStackActions;
  cards: T[];
  isWide?: boolean;
  isWithHero?: boolean;
  hasNavigationDots?: boolean;
  renderCard: ({
    card,
    allCards,
    index,
  }: {
    card: T;
    allCards: T[];
    index: number;
  }) => React.ReactElement | null;
}) => {
  /**
   *  TODO / BUG / HOTFIX
   *  Is the CREPO conent array is empty the app will critically fail.
   */
  if (!cards || cards.length === 0) {
    console.warn("Error loading Card Stack, array is empty or unedfined");
    return null;
  }

  return (
    <div
      className={cx("container", {
        "container--wide": isWide,
        "container--with-hero": isWithHero,
      })}
      data-card-stack="container" // <- Used as a selector in other components.
    >
      <div
        className={cx("cards-wrapper", {
          "cards-wrapper--vertical": cards.length === 1,
        })}
        data-card-stack="cards-wrapper" // <- Used as a selector in other components.
        role="group"
        aria-label="Card carousel"
      >
        {((numberOfCards) => {
          /* We are using `role="group"`, but could also be `"region"` if we tighten down the page navigation landmarks */
          switch (numberOfCards) {
            case 1:
              return (
                <CardContainer
                  allCards={cards}
                  array={cards}
                  index={0}
                  cardStackState={cardStackState}
                  noSliding={true}
                  cardStackActions={cardStackActions}
                  realCardLength={cards.length}
                >
                  {renderCard({
                    card: cards[0],
                    allCards: cards,
                    index: 0,
                  })}
                </CardContainer>
              );
            default: {
              /** When there are not enough cards in the DOM then the animations
               * don't actually work properly. So we "pad" the cards by
               * duplicating the array as many times as is necessary to make it
               * have a minimum length of {CARDS_IN_DOM}.
               */

              /**
               * TODO / BUG / HOTFIX
               * If `cards.length` is 0 the app will crash (divide by 0).
               * We should always have cards, CREPO queries are fragile
               */
              const multiplicationFactor = cards.length
                ? Math.ceil(CARDS_IN_DOM / cards.length)
                : 0;

              const finalCards: KeyedCard<T>[] = Array.from(
                Array(multiplicationFactor),
              )
                .flatMap(() => cards)
                .map((card, index) => ({ ...card, key: index }));

              return (
                <>
                  <div className={cx("cards-stage")}>
                    {rotateArray(
                      finalCards,
                      cardStackState.topCardIndex +
                        1 /* The +1 is so that the last card is always at the top of the stack, waiting to be animated in from above/left */,
                    )
                      .slice(0, CARDS_IN_DOM)
                      .map((card, index, array) => (
                        <CardContainer
                          key={card.key}
                          allCards={finalCards}
                          array={array}
                          index={index}
                          cardStackState={cardStackState}
                          noSliding={false}
                          cardStackActions={cardStackActions}
                          realCardLength={cards.length}
                        >
                          {renderCard({
                            card,
                            allCards: finalCards,
                            index: index,
                          })}
                        </CardContainer>
                      ))}
                    <NavigationButtons
                      cardsLength={finalCards.length}
                      cardStackActions={cardStackActions}
                      cardStackState={cardStackState}
                    />
                  </div>
                  {/**
                   * we use `cards.length` rather than `cardsWithLastAsFirst.length`, as the latter had
                   * issues for small numbers of cards on the athelete stack
                   */}
                  {hasNavigationDots && (
                    <NavigationDots
                      className={cx("dots")}
                      dotsCount={cards.length}
                      activeIndex={cardStackState.topCardIndex % cards.length}
                      onClickDot={(dotIndex) =>
                        cardStackActions.jumpToCardDesktop(
                          cards.length,
                          dotIndex,
                        )
                      }
                      dotLabelRenderFn={(dotIndex) =>
                        `Go to card ${dotIndex + 1} of {cards.length}`
                      }
                    />
                  )}
                </>
              );
            }
          }
        })(cards.length)}
      </div>
    </div>
  );
};

const CardContainer = <T = unknown,>({
  children,
  allCards,
  array,
  index,
  cardStackState,
  noSliding,
  cardStackActions,
  realCardLength,
}: {
  children: React.ReactNode;
  allCards: T[];
  array: T[];
  index: number;
  cardStackState: CardStackState;
  noSliding: boolean;
  cardStackActions: CardStackActions;
  realCardLength: number;
}) => (
  <div
    className={cx("card-container")}
    aria-hidden={index === 1 ? undefined : "true"}
    role="group"
    aria-label={`Card ${(index % realCardLength) + 1} of ${realCardLength}`}
    onTouchStart={
      noSliding
        ? undefined
        : (event) => cardStackActions.setInitialTouchPoint(event)
    }
    onTouchEnd={
      noSliding
        ? undefined
        : () => cardStackActions.finishCardAnimation(allCards.length)
    }
    onTouchMove={
      noSliding ? undefined : (event) => cardStackActions.moveTopCard(event)
    }
    onClick={
      noSliding
        ? undefined
        : (event) => {
            if (
              window.innerWidth < TABLET_BREAKPOINT &&
              event.target instanceof Element &&
              event.target.tagName !== "A"
            ) {
              cardStackActions.goToNextCard(allCards.length);
            }
          }
    }
    style={
      noSliding
        ? undefined
        : {
            ...calculateCSSTransforms(index, cardStackState),
            pointerEvents: index === 1 ? "all" : "none",
            zIndex: array.length - index,
            opacity: index === 0 || index === array.length - 1 ? 0 : 1,
            transition:
              index === 1 && cardStackState.transformationsBlocked
                ? "none"
                : undefined,
          }
    }
  >
    {children}
  </div>
);

function calculateCSSTransforms(
  index: number,
  block: CardStackState,
): CSSProperties {
  const TOP_CARD_ANGLE = 30;

  switch (index) {
    case 0:
      return {
        translate: `${block.lastDirection === "left" ? "-150%" : "150%"} 0`,
        scale: 1,
        rotate: `${
          block.lastDirection === "left" ? -TOP_CARD_ANGLE : TOP_CARD_ANGLE
        }deg`,
      };
    case 1:
      return {
        translate: `${block.diffX}px 0`,
        scale: `calc(var(--_card-stack-top-card-scale) - ${index} / var(--_card-stack-card-scaling-factor))`,
        rotate: `calc(${
          block.initialX
            ? `${block.diffX} / var(--_card-stack-card-scaling-factor)`
            : 0
        } * 1deg)`,
      };
    default:
      return {
        translate: `0 calc(${
          index - 1
        }% * var(--_card-stack-card-spacing-factor))`,
        scale: `calc(var(--_card-stack-top-card-scale) - ${index} / var(--_card-stack-card-scaling-factor))`,
        rotate: "0deg",
      };
  }
}

const NavigationButtons = ({
  cardsLength,
  cardStackActions,
  cardStackState,
  buttonKind,
}: {
  cardsLength: number;
  cardStackActions: CardStackActions;
  cardStackState: CardStackState;
  buttonKind?: CosmosButtonProps["kind"];
}) => {
  return (
    <div className={cx("navigation")}>
      <div className={cx("slider-button-wrapper")}>
        <SliderButton
          kind="previous"
          className={cx("slider-button", "slider-button--prev")}
          accessibilityLabel="Previous card"
          onClick={() => {
            cardStackActions.goToPreviousCardDesktop(cardsLength);
          }}
          buttonKind={buttonKind ?? undefined}
          disabled={cardStackState.isAnimating}
        />
        <SliderButton
          kind="next"
          className={cx("slider-button", "slider-button--next")}
          accessibilityLabel="Next card"
          onClick={() => {
            cardStackActions.goToNextCardDesktop(cardsLength);
          }}
          buttonKind={buttonKind ?? undefined}
          disabled={cardStackState.isAnimating}
        />
      </div>
    </div>
  );
};
