export const rotateArray = <T = unknown>(
  items: T[],
  increments: number,
): T[] => {
  if (increments < 0) {
    return rotateArray(items, items.length + (increments % items.length));
  }
  const newZeroIndex = increments % items.length;
  return items.concat(items).slice(newZeroIndex, newZeroIndex + items.length);
};
