import { useEffect, useRef, useState } from "react";

export const useObserveElementRef = <T extends HTMLElement>({
  threshold,
  rootMargin,
}: {
  threshold?: number | number[];
  rootMargin?: string;
}) => {
  const ref = useRef<T | null>(null);
  const [hasIntersected, setHasIntersected] = useState<boolean>(false);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setHasIntersected((current) => current || entry.isIntersecting);
            setIsIntersecting(() => entry.isIntersecting);
          });
        },
        {
          threshold,
          rootMargin,
        },
      );

      intersectionObserver.observe(element);

      return () => intersectionObserver.disconnect();
    }
  }, [threshold, rootMargin]);

  return {
    isIntersecting,
    hasIntersected,
    ref,
  };
};
