import { useState } from "react";
import type { TouchEvent } from "react";

export interface CardStackState {
  initialX: number;
  diffX: number;
  topCardIndex: number;
  transformationsBlocked: boolean;
  lastDirection?: "left" | "right";
  isAnimating: boolean;
}

const SWIPE_DIRECTION_THRESHOLD = 50;
const ANIMATION_TIME = 500; // time it takes to animate 1 card

export const useCardStackState = ({ rtl }: { rtl: boolean }) => {
  const [cardStackState, setState] = useState<CardStackState>({
    initialX: 0,
    diffX: 0,
    topCardIndex: 0,
    transformationsBlocked: false,
    isAnimating: false,
  });

  const goToNextCardReducer = ([cardsLength, current]: [
    number,
    CardStackState,
  ]): CardStackState => {
    const wasLastDirectionLeft = current.diffX < 0;

    return {
      ...current,
      topCardIndex: (current.topCardIndex + 1) % cardsLength,
      initialX: 0,
      diffX: 0,
      lastDirection: wasLastDirectionLeft || rtl ? "left" : "right",
      transformationsBlocked: false,
    };
  };

  const cardStackActions = {
    moveTopCard: (event: TouchEvent) =>
      // onTouchMove
      setState((current) => ({
        ...current,
        initialX: current.initialX,
        diffX: event.targetTouches[0].clientX - current.initialX,
        transformationsBlocked: true,
      })),
    goToNextCardDesktop: (cardsLength: number) =>
      setState((current) => ({
        ...current,
        topCardIndex: (current.topCardIndex + 1) % cardsLength,
        initialX: 0,
        diffX: 0,
        lastDirection: "right",
        transformationsBlocked: false,
      })),
    setInitialTouchPoint: (event: TouchEvent) =>
      // onTouchStart set some initial values
      setState((current) => ({
        ...current,
        initialX: event.targetTouches[0].clientX,
        diffX: 0,
        lastDirection: undefined,
      })),
    goToNextCard: (cardsLength: number) =>
      // Touch tap
      setState((current) => goToNextCardReducer([cardsLength, current])),
    goToPreviousCardDesktop: (cardsLength: number) =>
      setState((current) => {
        const currentTopCardIndex =
          current.topCardIndex === 0 ? cardsLength : current.topCardIndex;

        return {
          ...current,
          topCardIndex: (currentTopCardIndex - 1) % cardsLength,
          initialX: 0,
          diffX: 0,
          lastDirection: "left",
          transformationsBlocked: false,
        };
      }),
    finishCardAnimation: (albumLength: number) =>
      // onTouchEnd
      setState((current) => {
        if (
          current.diffX > SWIPE_DIRECTION_THRESHOLD ||
          current.diffX < -SWIPE_DIRECTION_THRESHOLD
        ) {
          return goToNextCardReducer([albumLength, current]);
        }

        return {
          ...current,
          initialX: 0,
          diffX: 0,
        };
      }),
    jumpToCardDesktop: (cardsLength: number, index: number) => {
      /* This is used on the navigation dots, clicking a dot will cycle through X cards */

      const diffSlides = index - cardStackState.topCardIndex; // slide direction: positive/negative number of slides to move
      const numberOfSlides = Math.abs(diffSlides); // number of slides to move (always positive)

      // Do nothing if it's animating
      if (cardStackState.isAnimating) {
        return;
      }

      // If user isn't clicking on the currently active Nav Dot
      if (diffSlides > 0) {
        // Next
        setState((current) => ({
          ...current,
          isAnimating: true,
        })); // Turn on `isAnimating` flag to disable other click functionality
        let delay = 0;
        for (let i = 0; i < numberOfSlides; i++) {
          setTimeout(() => {
            cardStackActions.goToNextCardDesktop(cardsLength);
          }, delay);
          delay += ANIMATION_TIME; // incrementally increase the delay for each timeout
        } // Trigger a fake `goToNextCardDesktop` press after a set time for each slide
        setTimeout(() => {
          setState((current) => ({
            ...current,
            isAnimating: false,
          }));
        }, numberOfSlides * ANIMATION_TIME); // turn off `isAnimating` after previous timeouts are theoretically finished
      } else if (diffSlides < 0) {
        // Previous
        setState((current) => ({
          ...current,
          isAnimating: true,
        }));
        let delay = 0;
        for (let i = 0; i < numberOfSlides; i++) {
          setTimeout(() => {
            cardStackActions.goToPreviousCardDesktop(cardsLength);
          }, delay);
          delay += ANIMATION_TIME;
        }
        setTimeout(() => {
          setState((current) => ({
            ...current,
            isAnimating: false,
          }));
        }, numberOfSlides * ANIMATION_TIME);
      } else {
        // do nothing
      }
    },
  };

  return { cardStackState, cardStackActions };
};

export type CardStackActions = ReturnType<
  typeof useCardStackState
>["cardStackActions"];
